import { Col } from "react-bootstrap";
import reactIcon from "../assets/img/react-2.svg";
import javascriptIcon from "../assets/img/logo-javascript.svg";
import typescriptIcon from "../assets/img/typescript.svg";
import htmlIcon from "../assets/img/html-1.svg";
import cssIcon from "../assets/img/css-3.svg";
import dartIcon from "../assets/img/dartlang-icon.svg";
import flutterIcon from "../assets/img/flutter-logo.svg";
import tailwindIcon from "../assets/img/tailwind-css-2.svg";
import mongodbIcon from "../assets/img/mongodb.png";
import sqlIcon from "../assets/img/sql.svg";
import nodejsIcon from "../assets/img/nodejs-icon.svg";
import pythonIcon from "../assets/img/python-5.svg";
import apolloIcon from "../assets/img/apollo.svg";
import graphqlIcon from "../assets/img/graphql-logo-2.svg";
import flaskIcon from "../assets/img/flask.svg";
import expressIcon from "../assets/img/expresss.svg";
import cppIcon from "../assets/img/c.svg";
import cIcon from "../assets/img/c-1.svg";
import phpIcon from "../assets/img/php-1.svg";
import jenkinsIcon from "../assets/img/jenkins.png";
import gcpIcon from "../assets/img/gcp.svg";
import jiraIcon from "../assets/img/jira.png";
import awsIcon from "../assets/img/aws.svg";
import dockerIcon from "../assets/img/docker.svg";
import javaIcon from "../assets/img/java.svg";
import ProjectInfo from "./ProjectInfo";

function nameToIcon(name) {
  switch (name) {
    case "React":
      return reactIcon;
    case "JavaScript":
      return javascriptIcon;
    case "TypeScript":
      return typescriptIcon;
    case "HTML":
      return htmlIcon;
    case "CSS":
      return cssIcon;
    case "Dart":
      return dartIcon;
    case "Flutter":
      return flutterIcon;
    case "Tailwind":
      return tailwindIcon;
    case "Node.js":
      return nodejsIcon;
    case "Python":
      return pythonIcon;
    case "Apollo":
      return apolloIcon;
    case "GraphQL":
      return graphqlIcon;
    case "Flask":
      return flaskIcon;
    case "Express":
      return expressIcon;
    case "C++":
      return cppIcon;
    case "C":
      return cIcon;
    case "PHP":
      return phpIcon;
    case "MongoDB":
      return mongodbIcon;
    case "SQL":
      return sqlIcon;
    case "Jenkins":
      return jenkinsIcon;
    case "GoogleCloud":
      return gcpIcon;
    case "Jira":
      return jiraIcon;
    case "AWS":
      return awsIcon;
    case "Docker":
      return dockerIcon;
    case "Java":
      return javaIcon;
    default:
      return "";
  }
}

const ProjectCard = ({
  title,
  description,
  usedTechnologies,
  gradientColor,
  selectCard,
  details,
  imgUrl,
  isSelected,
}) => {
  // Reemplazando imgUrl por un contenedor personalizado
  const containerStyle = {
    "--color-gradiente-final": gradientColor || "rgb(56, 125, 253)",
    transition: "all 0.3s ease-out",
    cursor: isSelected ? "" : "pointer",
  };

  const detailedContainerStyle = isSelected
    ? {
        width: "100%",
        borderRadius: "20px",
        backgroundImage: `linear-gradient(90.21deg,
          rgba(0, 0, 0, 0.9) -5%,
          ${gradientColor} 150%)`,
        height: "auto", // Altura del contenedor
        //display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "hidden",
      }
    : {};

  const handleCardClick = () => {
    if (!isSelected) {
      selectCard(title);
    }
  };

  return (
    <Col sm={1} md={isSelected ? 12 : 4} style={containerStyle}>
      <div
        className={`proj-imgbx ${isSelected ? "selected" : "not-selected"}`}
        onClick={handleCardClick}
      >
        {isSelected && (
          <div className="detailed-container" style={detailedContainerStyle}>
            <div
              className="deselect-arrow"
              onClick={(e) => {
                e.stopPropagation();
                selectCard(null);
              }}
            >
              ←
            </div>
            <ProjectInfo title={title} description={description} usedTechnologies={usedTechnologies} gradientColor={gradientColor} imgUrl={imgUrl} details={details}/>
          </div>
        )}
        {!isSelected && (
          <div
            style={{
              width: "100%",
              height: "380px",
              borderRadius: "20px",
              background: gradientColor, // Utiliza el color de gradiente como fondo
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              margin: "0 auto",

            }}
          > <img src={imgUrl} alt={imgUrl} style={{ width: "250px" }}></img></div>
        )}
        {!isSelected && (
          <div
            className={`proj-txtx ${isSelected ? "selected" : "not-selected"}`}
          >
            <h4>{title}</h4>
            <p className="p-3">{description}</p>
            <div className="flex flex-wrap justify-center items-center">
              {usedTechnologies.map((tech, index) => (
                <img
                  key={index}
                  src={nameToIcon(tech)}
                  alt={tech}
                  className="w-10 m-1"
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </Col>
  );
};

export default ProjectCard;
