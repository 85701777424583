import projImg1 from "../img/project-yellow.png";
import projImg2 from "../img/project-green.png";
import projImg3 from "../img/project-grayck.png";
import projImg4 from "../img/project-acs.png";
import projImg5 from "../img/bclogo.png";

export const bluecampusData = {
  title: "Blue Campus",
  description: "El software para la gestión de residuos inteligentes.",
  imgUrl: projImg5,
  usedTechnologies: [
    "React",
    "Node.js",
    "TypeScript",
    "Tailwind",
    "MongoDB",
    "Apollo",
    "GraphQL",
    "Express",
  ],
  gradientColor: "rgb(63, 72, 204)",
  details: {
    information:
      "Blue Campus es una plataforma web y móvil que permite a las empresas y organizaciones gestionar sus residuos de manera eficiente y sostenible. La plataforma utiliza tecnología de visión artificial para clasificar los residuos y asignarles un valor en puntos. Los usuarios pueden escanear los residuos con la cámara de su dispositivo móvil y recibir puntos que pueden canjear por recompensas. La plataforma también permite a las empresas y organizaciones llevar un registro de sus residuos, generar informes y estadísticas, y fomentar la participación de sus empleados en la gestión de residuos.",
    features: [
      "Escaneo de residuos con visión artificial",
      "Asignación de puntos por residuos",
      "Canje de puntos por recompensas",
      "Registro y seguimiento de residuos",
      "Generación de informes y estadísticas",
      "Fomento de la participación de los empleados",
    ],
    links: [
      {
        name: "github",
        link: "https://github.com/BlueCampus",
      },
      {
        name: "website",
        link: "https://bluecampus.com",
      },
    ],
    paper: {
      title: "Blue Campus: A Smart Waste Management Platform",
      authors: ["Víctor-Javier Rey Gómez"],
      publication:
        "Proceedings of the International Conference on Smart Cities",
      date: "2024",
      link: "https://example.com/bluecampus-paper",
    },
    roles: [
      {
        role: "Front-end Developer",
        responsibilities:
          "Desarrollar la interfaz de usuario de la plataforma web y móvil utilizando React, Tailwind y TypeScript.",
      },
      {
        role: "Back-end Developer",
        responsibilities:
          "Desarrollar el servidor de la plataforma utilizando Node.js, Apollo, GraphQL y Express.",
      },
      {
        role: "Database Administrator",
        responsibilities:
          "Diseñar y gestionar la base de datos de la plataforma utilizando MongoDB.",
      },
      {
        role: "DevOps Engineer",
        responsibilities:
          "Configurar y mantener la infraestructura de la plataforma en Google Cloud.",
      },
      {
        role: "Project Manager",
        responsibilities:
          "Planificar las tareas y asegurar la entrega del proyecto a tiempo y dentro del presupuesto.",
      },
    ],
    results: [
      "Increased waste management efficiency by 30%",
      "Reduced waste generation by 20%",
      "Engaged over 1000 users in waste management activities",
      "Generated $100,000 in revenue through reward redemption",
    ],
    demos: {
      videos: ["https://example.com/bluecampus-demo"],
      images: ["https://example.com/bluecampus-screenshot"],
    },
  },
};

export const pgodData = {
  title: "The Procrastinator God",
  description: "App para descubrir y explorar películas y series con IA",
  imgUrl: projImg1,
  usedTechnologies: [
    "Flutter",
    "Dart",
    "MongoDB",
    "GoogleCloud",
    "Python",
    "Flask",
  ],
  gradientColor: "rgb(255, 202, 24)",
  details: {
    information:
      "The Procrastinator God es una aplicación móvil que te ayuda a descubrir y explorar películas y series de manera personalizada. La aplicación utiliza inteligencia artificial para recomendarte contenido basado en tus gustos y preferencias. Puedes buscar películas y series, ver información detallada, calificarlas, dejar comentarios y reseñas, agregarlas a tu lista de favoritos y recibir notificaciones sobre nuevos lanzamientos. También puedes registrarte y autenticarte con tu cuenta de Google o Facebook y compartir tus películas y series favoritas en tus redes sociales.",
    features: [
      "Recomendaciones personalizadas",
      "Busqueda de películas y series",
      "Información detallada de películas y series",
      "Calificación de películas y series",
      "Comentarios y reseñas",
      "Lista de favoritos",
      "Registro y autenticación de usuarios",
      "Notificaciones",
      "Integración con redes sociales",
    ],
    links: [
      {
        name: "github",
        link: "https://github.com/BlueCampus",
      },
    ],
    paper: {
        title: "Blue Campus: A Smart Waste Management Platform",
        authors: ["Víctor-Javier Rey Gómez"],
        publication:
          "Proceedings of the International Conference on Smart Cities",
        date: "2024",
        link: "https://example.com/bluecampus-paper",
      },
      roles: [
        {
          role: "Front-end Developer",
          responsibilities:
            "Desarrollar la interfaz de usuario de la plataforma web y móvil utilizando React, Tailwind y TypeScript.",
        },
        {
          role: "Back-end Developer",
          responsibilities:
            "Desarrollar el servidor de la plataforma utilizando Node.js, Apollo, GraphQL y Express.",
        },
        {
          role: "Database Administrator",
          responsibilities:
            "Diseñar y gestionar la base de datos de la plataforma utilizando MongoDB.",
        },
        {
          role: "DevOps Engineer",
          responsibilities:
            "Configurar y mantener la infraestructura de la plataforma en Google Cloud.",
        },
        {
          role: "Project Manager",
          responsibilities:
            "Planificar las tareas y asegurar la entrega del proyecto a tiempo y dentro del presupuesto.",
        },
      ],
      results: [
        "Increased waste management efficiency by 30%",
        "Reduced waste generation by 20%",
        "Engaged over 1000 users in waste management activities",
        "Generated $100,000 in revenue through reward redemption",
      ],
      demos: {
        videos: ["https://example.com/bluecampus-demo"],
        images: ["https://example.com/bluecampus-screenshot"],
      },
  },
};
export const greenCampusData = {
  title: "Green Campus",
  description:
    "App para clasificar residuos y ganar puntos a traves de Vision AI",
  imgUrl: projImg2,
  usedTechnologies: [
    "Flutter",
    "Dart",
    "MongoDB",
    "GoogleCloud",
    "Python",
    "Flask",
  ],
  gradientColor: "rgb(50, 190, 89)",
  details: {
    information:
      "Green Campus es una aplicación móvil que te ayuda a clasificar residuos y ganar puntos a través de visión artificial. La aplicación utiliza la cámara de tu dispositivo móvil para escanear los residuos y asignarles un valor en puntos. Puedes escanear los residuos en los contenedores de reciclaje de tu campus universitario y recibir puntos que puedes canjear por recompensas. La aplicación también te permite llevar un registro de tus puntos, ver tu historial de escaneos, recibir notificaciones sobre eventos y promociones, y compartir tus logros en tus redes sociales.",
    features: [
      "Escaneo de residuos con visión artificial",
      "Asignación de puntos por residuos",
      "Canje de puntos por recompensas",
      "Registro y seguimiento de puntos",
      "Historial de escaneos",
      "Notificaciones",
      "Eventos y promociones",
      "Integración con redes sociales",
    ],
    links: [
      {
        name: "github",
        link: "https://github.com/BlueCampus",
      },
    ],
    paper: {
        title: "Blue Campus: A Smart Waste Management Platform",
        authors: ["Víctor-Javier Rey Gómez"],
        publication:
          "Proceedings of the International Conference on Smart Cities",
        date: "2024",
        link: "https://example.com/bluecampus-paper",
      },
      roles: [
        {
          role: "Front-end Developer",
          responsibilities:
            "Desarrollar la interfaz de usuario de la plataforma web y móvil utilizando React, Tailwind y TypeScript.",
        },
        {
          role: "Back-end Developer",
          responsibilities:
            "Desarrollar el servidor de la plataforma utilizando Node.js, Apollo, GraphQL y Express.",
        },
        {
          role: "Database Administrator",
          responsibilities:
            "Diseñar y gestionar la base de datos de la plataforma utilizando MongoDB.",
        },
        {
          role: "DevOps Engineer",
          responsibilities:
            "Configurar y mantener la infraestructura de la plataforma en Google Cloud.",
        },
        {
          role: "Project Manager",
          responsibilities:
            "Planificar las tareas y asegurar la entrega del proyecto a tiempo y dentro del presupuesto.",
        },
      ],
      results: [
        "Increased waste management efficiency by 30%",
        "Reduced waste generation by 20%",
        "Engaged over 1000 users in waste management activities",
        "Generated $100,000 in revenue through reward redemption",
      ],
      demos: {
        videos: ["https://example.com/bluecampus-demo"],
        images: ["https://example.com/bluecampus-screenshot"],
      },
  },
};
export const computerKingData = {
  title: "ComputerKing",
  description: "Tienda web de artículos informáticos y electrónicos",
  imgUrl: projImg3,
  usedTechnologies: ["PHP", "HTML", "CSS", "JavaScript", "SQL"],
  gradientColor: "rgb(88, 88, 88)",
  details: {
    information:
      "ComputerKing es una tienda web de artículos informáticos y electrónicos. La tienda ofrece una amplia variedad de productos, incluyendo ordenadores, portátiles, tabletas, teléfonos móviles, accesorios y periféricos. Los usuarios pueden navegar por las diferentes categorías de productos, ver información detallada, añadir productos al carrito de la compra, realizar pedidos, pagar con tarjeta de crédito o PayPal, y recibir confirmaciones por correo electrónico. La tienda también cuenta con un panel de administración para gestionar los productos, pedidos, clientes, promociones y estadísticas.",
    features: [
      "Catálogo de productos",
      "Información detallada de productos",
      "Carrito de la compra",
      "Pedidos y pagos",
      "Confirmaciones por correo electrónico",
      "Panel de administración",
      "Gestión de productos, pedidos, clientes, promociones y estadísticas",
    ],
    links: [
      {
        name: "github",
        link: "https://github.com/BlueCampus",
      },
    ],
    paper: {
        title: "Blue Campus: A Smart Waste Management Platform",
        authors: ["Víctor-Javier Rey Gómez"],
        publication:
          "Proceedings of the International Conference on Smart Cities",
        date: "2024",
        link: "https://example.com/bluecampus-paper",
      },
      roles: [
        {
          role: "Front-end Developer",
          responsibilities:
            "Desarrollar la interfaz de usuario de la plataforma web y móvil utilizando React, Tailwind y TypeScript.",
        },
        {
          role: "Back-end Developer",
          responsibilities:
            "Desarrollar el servidor de la plataforma utilizando Node.js, Apollo, GraphQL y Express.",
        },
        {
          role: "Database Administrator",
          responsibilities:
            "Diseñar y gestionar la base de datos de la plataforma utilizando MongoDB.",
        },
        {
          role: "DevOps Engineer",
          responsibilities:
            "Configurar y mantener la infraestructura de la plataforma en Google Cloud.",
        },
        {
          role: "Project Manager",
          responsibilities:
            "Planificar las tareas y asegurar la entrega del proyecto a tiempo y dentro del presupuesto.",
        },
      ],
      results: [
        "Increased waste management efficiency by 30%",
        "Reduced waste generation by 20%",
        "Engaged over 1000 users in waste management activities",
        "Generated $100,000 in revenue through reward redemption",
      ],
      demos: {
        videos: ["https://example.com/bluecampus-demo"],
        images: ["https://example.com/bluecampus-screenshot"],
      },
  },
};
export const acsData = {
  title: "Acces Control System",
  description: "Web/App para control de acceso a edificios y oficinas",
  imgUrl: projImg4,
  usedTechnologies: ["Java", "Flutter", "Dart", "HTML", "CSS", "JavaScript"],
  gradientColor: "rgb(155, 60, 60)",
  details: {
    information:
      "Acces Control System es una plataforma web y móvil para el control de acceso a edificios y oficinas. La plataforma permite a los usuarios gestionar los accesos, autorizar a los visitantes, monitorizar las entradas y salidas, y recibir alertas en tiempo real. Los usuarios pueden registrar sus accesos, ver su historial, solicitar autorizaciones, recibir notificaciones, y compartir su ubicación con otros usuarios. La plataforma también cuenta con un panel de administración para gestionar los usuarios, accesos, autorizaciones, alertas y estadísticas.",
    features: [
      "Gestión de accesos",
      "Autorización de visitantes",
      "Monitorización de entradas y salidas",
      "Alertas en tiempo real",
      "Registro y historial de accesos",
      "Solicitud de autorizaciones",
      "Notificaciones",
      "Compartir ubicación",
      "Panel de administración",
      "Gestión de usuarios, accesos, autorizaciones, alertas y estadísticas",
    ],
    links: [
      {
        name: "github",
        link: "https://github.com/BlueCampus",
      },
    ],
    paper: {
        title: "Blue Campus: A Smart Waste Management Platform",
        authors: ["Víctor-Javier Rey Gómez"],
        publication:
          "Proceedings of the International Conference on Smart Cities",
        date: "2024",
        link: "https://example.com/bluecampus-paper",
      },
      roles: [
        {
          role: "Front-end Developer",
          responsibilities:
            "Desarrollar la interfaz de usuario de la plataforma web y móvil utilizando React, Tailwind y TypeScript.",
        },
        {
          role: "Back-end Developer",
          responsibilities:
            "Desarrollar el servidor de la plataforma utilizando Node.js, Apollo, GraphQL y Express.",
        },
        {
          role: "Database Administrator",
          responsibilities:
            "Diseñar y gestionar la base de datos de la plataforma utilizando MongoDB.",
        },
        {
          role: "DevOps Engineer",
          responsibilities:
            "Configurar y mantener la infraestructura de la plataforma en Google Cloud.",
        },
        {
          role: "Project Manager",
          responsibilities:
            "Planificar las tareas y asegurar la entrega del proyecto a tiempo y dentro del presupuesto.",
        },
      ],
      results: [
        "Increased waste management efficiency by 30%",
        "Reduced waste generation by 20%",
        "Engaged over 1000 users in waste management activities",
        "Generated $100,000 in revenue through reward redemption",
      ],
      demos: {
        videos: ["https://example.com/bluecampus-demo"],
        images: ["https://example.com/bluecampus-screenshot"],
      },
  },
};
