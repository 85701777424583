import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { useState } from "react";
import uniIcon from "../assets/img/uni.svg";
import lcIcon from "../assets/img/lclogo.png";
import proIcon from "../assets/img/pro.svg";
import {
  bluecampusData,
  greenCampusData,
  pgodData,
  computerKingData,
  acsData,
} from "../assets/data/data";

export const Projects = () => {
  const projectsPro = [bluecampusData];

  const projectsUniv = [greenCampusData, pgodData, computerKingData, acsData];
  const [selectedCard, setSelectedCard] = useState(null);

  const renderProjectCards = (projects) => {
    return projects.map((project, index) => {
      // Renderizar solo si no hay selección o si este proyecto está seleccionado
      if (!selectedCard || selectedCard === project.title) {
        return (
          <ProjectCard
            key={project.title}
            {...project}
            selectCard={(selectedTitle) =>
              setSelectedCard((prev) =>
                prev === selectedTitle ? null : selectedTitle
              )
            }
            isSelected={selectedCard === project.title}
          />
        );
      }
      return null; // No renderizar este proyecto si otro está seleccionado
    });
  };

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects</h2>
                  <p className="text-center w-[70%] m-auto p-3">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                  <Tab.Container
                    id="projects-tabs"
                    defaultActiveKey="first"
                    onSelect={() => setSelectedCard(null)}
                  >
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first" className="cursor-pointer">
                          <div className="flex items-center justify-center">
                            <img
                              src={proIcon}
                              alt="Professional Projects"
                              className="w-10 h-10"
                            />
                            <p className="text-center mt-4 ml-2 hidden md:block">
                              Professional Projects
                            </p>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second" className="cursor-pointer">
                          <div className="flex items-center justify-center">
                            <img
                              src={uniIcon}
                              alt="University Projects"
                              className="w-10 h-10"
                            />
                            <p className="text-center mt-4 ml-3 hidden md:block">
                              University Projects
                            </p>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third" className="cursor-pointer">
                          <div className="flex items-center justify-center">
                            <img
                              src={lcIcon}
                              alt="LemonCode Projects"
                              className="w-10 h-10"
                            />
                            <p className="text-center mt-4 ml-3 hidden md:block">
                              LemonCode Projects
                            </p>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>{renderProjectCards(projectsPro)}</Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>{renderProjectCards(projectsUniv)}</Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p className="text-center">In process...</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="img"></img>
    </section>
  );
};
