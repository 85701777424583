import { Col } from "react-bootstrap";
import reactIcon from "../assets/img/react-2.svg";
import javascriptIcon from "../assets/img/logo-javascript.svg";
import typescriptIcon from "../assets/img/typescript.svg";
import htmlIcon from "../assets/img/html-1.svg";
import cssIcon from "../assets/img/css-3.svg";
import dartIcon from "../assets/img/dartlang-icon.svg";
import flutterIcon from "../assets/img/flutter-logo.svg";
import tailwindIcon from "../assets/img/tailwind-css-2.svg";
import mongodbIcon from "../assets/img/mongodb.png";
import sqlIcon from "../assets/img/sql.svg";
import nodejsIcon from "../assets/img/nodejs-icon.svg";
import pythonIcon from "../assets/img/python-5.svg";
import apolloIcon from "../assets/img/apollo.svg";
import graphqlIcon from "../assets/img/graphql-logo-2.svg";
import flaskIcon from "../assets/img/flask.svg";
import expressIcon from "../assets/img/expresss.svg";
import cppIcon from "../assets/img/c.svg";
import cIcon from "../assets/img/c-1.svg";
import phpIcon from "../assets/img/php-1.svg";
import jenkinsIcon from "../assets/img/jenkins.png";
import gcpIcon from "../assets/img/gcp.svg";
import jiraIcon from "../assets/img/jira.png";
import awsIcon from "../assets/img/aws.svg";
import dockerIcon from "../assets/img/docker.svg";
import javaIcon from "../assets/img/java.svg";

function nameToIcon(name) {
  switch (name) {
    case "React":
      return reactIcon;
    case "JavaScript":
      return javascriptIcon;
    case "TypeScript":
      return typescriptIcon;
    case "HTML":
      return htmlIcon;
    case "CSS":
      return cssIcon;
    case "Dart":
      return dartIcon;
    case "Flutter":
      return flutterIcon;
    case "Tailwind":
      return tailwindIcon;
    case "Node.js":
      return nodejsIcon;
    case "Python":
      return pythonIcon;
    case "Apollo":
      return apolloIcon;
    case "GraphQL":
      return graphqlIcon;
    case "Flask":
      return flaskIcon;
    case "Express":
      return expressIcon;
    case "C++":
      return cppIcon;
    case "C":
      return cIcon;
    case "PHP":
      return phpIcon;
    case "MongoDB":
      return mongodbIcon;
    case "SQL":
      return sqlIcon;
    case "Jenkins":
      return jenkinsIcon;
    case "GoogleCloud":
      return gcpIcon;
    case "Jira":
      return jiraIcon;
    case "AWS":
      return awsIcon;
    case "Docker":
      return dockerIcon;
    case "Java":
      return javaIcon;
    default:
      return "";
  }
}

const ProjectInfo = ({
  title,
  description,
  usedTechnologies,
  gradientColor,
  imgUrl,
  details,
}) => {
  // Reemplazando imgUrl por un contenedor personalizado

  return (
    <div className="max-w-6xl mx-auto p-5">
      <div className="flex justify-center items-center mb-2">
        <img
          src={imgUrl} // Asegúrate de que imgUrl sea la ruta correcta al logo.
          alt="Logo del Proyecto"
          className="mr-5 w-20"
        />
        <div className="flex">
          <h2 className="text-4xl font-semibold">{title}</h2>
        </div>
      </div>
      <p className="mb-4 text-center text-white">{description}</p>
      
      <div>
        <div>
          <p className="text-gray-200 mb-5">{details.information}</p>
        </div>
    
        <ul className="list-disc pl-5 mb-3">
          {details.features.map((feature, index) => (
            <li key={index} className="text-gray-600">
              {feature}
            </li>
          ))}
        </ul>
        <div className="flex flex-wrap items-center justify-start mb-4">
        {usedTechnologies.map((tech, index) => (
          <img
            key={index}
            src={nameToIcon(tech)}
            alt={tech}
            style={{ width: "40px", margin: "0 5px" }}
          />
        ))}
      </div>
        <div className="mb-3">
          <h6 className="font-semibold">Links:</h6>
          {details.links.map((link, index) => (
            <a
              key={index}
              href={link.link}
              className="text-blue-500 hover:text-blue-600"
            >
              {link.name}
            </a>
          ))}
        </div>
        <div className="mb-3">
          <h6 className="font-semibold">Paper:</h6>
          <p className="text-gray-600">Title: {details.paper.title}</p>
          <p className="text-gray-600">
            Authors: {details.paper.authors.join(", ")}
          </p>
          <p className="text-gray-600">
            Publication: {details.paper.publication}
          </p>
          <p className="text-gray-600">Date: {details.paper.date}</p>
          <a
            href={details.paper.link}
            className="text-blue-500 hover:text-blue-600"
          >
            Read Paper
          </a>
        </div>
        <div className="mb-3">
          <h6 className="font-semibold">Roles:</h6>
          <ul>
            {details.roles.map((role, index) => (
              <li key={index}>
                <strong>{role.role}</strong>
                <p className="text-gray-600">{role.responsibilities}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="mb-3">
          <h6 className="font-semibold">Results:</h6>
          <ul className="list-disc pl-5">
            {details.results.map((result, index) => (
              <li key={index} className="text-gray-600">
                {result}
              </li>
            ))}
          </ul>
        </div>
        <div className="mb-3">
          <h6 className="font-semibold">Demos:</h6>
          <div className="mb-2">
            <h7 className="font-semibold">Videos:</h7>
            {details.demos.videos.map((video, index) => (
              <a
                key={index}
                href={video}
                className="text-blue-500 hover:text-blue-600 ml-2"
              >
                Video {index + 1}
              </a>
            ))}
          </div>
          <div>
            <h7 className="font-semibold">Images:</h7>
            {details.demos.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Screenshot ${index + 1}`}
                className="inline-block w-48 mr-2 mb-2"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;
