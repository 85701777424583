import reactIcon from "../assets/img/react-2.svg";
import javascriptIcon from "../assets/img/logo-javascript.svg";
import typescriptIcon from "../assets/img/typescript.svg";
import htmlIcon from "../assets/img/html-1.svg";
import cssIcon from "../assets/img/css-3.svg";
import dartIcon from "../assets/img/dartlang-icon.svg";
import flutterIcon from "../assets/img/flutter-logo.svg";
import tailwindIcon from "../assets/img/tailwind-css-2.svg";
import mongodbIcon from "../assets/img/mongodb.png";
import sqlIcon from "../assets/img/sql.svg";
import nodejsIcon from "../assets/img/nodejs-icon.svg";
import pythonIcon from "../assets/img/python-5.svg";
import apolloIcon from "../assets/img/apollo.svg";
import graphqlIcon from "../assets/img/graphql-logo-2.svg";
import flaskIcon from "../assets/img/flask.svg";
import expressIcon from "../assets/img/express.svg";
import cppIcon from "../assets/img/c.svg";
import cIcon from "../assets/img/c-1.svg";
import phpIcon from "../assets/img/php-1.svg";
import jenkinsIcon from "../assets/img/jenkins.png";
import gcpIcon from "../assets/img/gcp.svg";
import jiraIcon from "../assets/img/jira.png";
import awsIcon from "../assets/img/aws.svg";
import dockerIcon from "../assets/img/docker.svg";
import javaIcon from "../assets/img/java.svg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";
import { SkillGroupCard } from "./SkillGroupCard";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx">
              <h2>Skills</h2>
              <h3>Front end</h3>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
                autoPlay={true}
                autoPlaySpeed={2500}
                customTransition="transform 1.5s ease-in-out"
                transitionDuration={1500}
                removeArrowOnDeviceType={["tablet", "mobile"]}
              >
                <div className="item">
                  <img
                    src={reactIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>React / React Native</h5>
                </div>
                <div className="item">
                  <img
                    src={javascriptIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>JavaScript</h5>
                </div>
                <div className="item">
                  <img
                    src={typescriptIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>TypeScript</h5>
                </div>
                <div className="item">
                  <img
                    src={htmlIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>HTML</h5>
                </div>
                <div className="item">
                  <img
                    src={cssIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>CSS</h5>
                </div>
                <div className="item">
                  <img
                    src={tailwindIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Tailwind</h5>
                </div>
                <div className="item">
                  <img
                    src={dartIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Dart</h5>
                </div>
                <div className="item">
                  <img
                    src={flutterIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Flutter</h5>
                </div>
              </Carousel>
              <h3>Back end</h3>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
                autoPlay={true}
                autoPlaySpeed={2500}
                customTransition="transform 1.5s ease-in-out"
                transitionDuration={1500}
                removeArrowOnDeviceType={["tablet", "mobile"]}
              >
                <div className="item">
                  <img
                    src={pythonIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Python</h5>
                </div>
                <div className="item">
                  <img
                    src={nodejsIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Node.js</h5>
                </div>
                <div className="item">
                  <img
                    src={apolloIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Apollo</h5>
                </div>
                <div className="item">
                  <img
                    src={graphqlIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>GraphQL</h5>
                </div>
                <div className="item">
                  <img
                    src={mongodbIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>MongoDB</h5>
                </div>
                <div className="item">
                  <img
                    src={flaskIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Flask</h5>
                </div>
                <div className="item">
                  <img
                    src={expressIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Express</h5>
                </div>
                <div className="item">
                  <img
                    src={cppIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>C++</h5>
                </div>
                <div className="item">
                  <img
                    src={cIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>C</h5>
                </div>
                <div className="item">
                  <img
                    src={javaIcon}
                    alt="javaIcon"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Java</h5>
                </div>
                <div className="item">
                  <img
                    src={phpIcon}
                    alt="Image"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>PHP</h5>
                </div>
                <div className="item">
                  <img
                    src={dockerIcon}
                    alt="dockerIcon"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Docker</h5>
                </div>
              </Carousel>
              <h3>Cloud & DevOps</h3>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
                autoPlay={true}
                autoPlaySpeed={2500}
                customTransition="transform 1.5s ease-in-out"
                transitionDuration={1500}
                removeArrowOnDeviceType={["tablet", "mobile"]}
              >
                <div className="item">
                  <img
                    src={awsIcon}
                    alt="awsIcon"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>AWS</h5>
                </div>
                <div className="item">
                  <img
                    src={gcpIcon}
                    alt="gcpIcon"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>GoogleCloud</h5>
                </div>
                <div className="item">
                  <img
                    src={jenkinsIcon}
                    alt="jenkinsIcon"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Jenkins</h5>
                </div>
                <div className="item">
                  <img
                    src={jiraIcon}
                    alt="jiraIcon"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5>Jira</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img
        className="background-image-left"
        src={colorSharp}
        alt="colorSharp"
      />
    </section>
  );
};
